





















import { Component, Vue } from "vue-property-decorator";
import { settingsModule } from "@/plugins/store/settings-module";
import { PvService, pvServiceData } from "@/backend/dto";

@Component
export default class extends Vue {
  private readonly userStore = settingsModule.context(this.$store);
  private readonly preferredServices = pvServiceData;

  get preferredService(): PvService {
    return this.userStore.getters.preferredPvService;
  }

  setPreferredService(value: PvService): void {
    this.userStore.actions.setPreferredPvService(value);
  }
}
