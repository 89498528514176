





















import { Component, Vue } from "vue-property-decorator";
import {
  RequestLanguage,
  settingsModule
} from "@/plugins/store/settings-module";

@Component
export default class extends Vue {
  private readonly settingsStore = settingsModule.context(this.$store);
  private readonly preferredLanguages: Map<RequestLanguage, string> = new Map([
    ["Default", this.$vuetify.lang.t("$vuetify.preferredLanguage.original")],
    ["Romaji", this.$vuetify.lang.t("$vuetify.preferredLanguage.romanized")],
    ["English", this.$vuetify.lang.t("$vuetify.preferredLanguage.english")],
    ["Japanese", this.$vuetify.lang.t("$vuetify.preferredLanguage.nonEnglish")]
  ]);

  get preferredLanguage(): RequestLanguage {
    return this.settingsStore.getters.preferredLanguage;
  }

  setPreferredLanguage(value: RequestLanguage): void {
    this.settingsStore.actions.setPreferredLanguage(value);
  }
}
