











import Vue from "vue";
import TheAppBar from "@/components/TheAppBar.vue";
import { Component } from "vue-property-decorator";

@Component({ components: { TheAppBar } })
export default class extends Vue {}
