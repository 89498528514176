













import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SwitchButtonTheme from "@/components/buttons/SwitchButtonTheme.vue";
import LogoutButton from "@/components/buttons/LogoutButton.vue";
import SwitchButtonPreferredLanguage from "@/components/buttons/SwitchButtonPreferredLanguage.vue";
import SwitchButtonLocale from "@/components/buttons/SwitchButtonLocale.vue";
import SwitchButtonItemsPerPage from "@/components/buttons/SwitchButtonItemsPerPage.vue";
import SwitchButtonPreferredService from "@/components/buttons/SwitchButtonPreferredService.vue";

@Component({
  components: {
    SwitchButtonPreferredService,
    SwitchButtonItemsPerPage,
    LogoutButton,
    SwitchButtonTheme,
    SwitchButtonPreferredLanguage,
    SwitchButtonLocale
  }
})
export default class extends Vue {
  @Prop({ default: false, type: Boolean })
  private readonly authenticated!: boolean;
}
