























import { Component, Vue } from "vue-property-decorator";
import {
  ItemsPerPage,
  itemsPerPageData,
  settingsModule
} from "@/plugins/store/settings-module";

@Component
export default class extends Vue {
  private readonly settingsStore = settingsModule.context(this.$store);
  private readonly items = itemsPerPageData;

  get itemsPerPage(): ItemsPerPage {
    return this.settingsStore.getters.itemsPerPage;
  }

  setItemsPerPage(value: ItemsPerPage): void {
    this.settingsStore.actions.setItemsPerPage(value);
  }
}
