







import { Component, Vue } from "vue-property-decorator";
import { settingsModule } from "@/plugins/store/settings-module";

@Component
export default class extends Vue {
  private settingsStore = settingsModule.context(this.$store);

  get darkTheme(): boolean {
    return this.settingsStore.getters.isDarkTheme;
  }

  set darkTheme(value: boolean) {
    this.settingsStore.actions.setTheme(value ? "Dark" : "Light");
  }
}
